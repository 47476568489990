import form from '@/templates/shared/_form.js'

export default class {
  static spec(checkItem, checklist, card) {
    const editMode = $tpu.editing.checkItemId == checkItem.id
    const ticked = checkItem.state == "complete"
    const checkIcon = ticked ? "check_box" : "check_box_outline_blank"
    return {
      "template": "thumbnail",
      "styleClasses": ["checklist-item"],
      "title": editMode ? null : checkItem.name,
      "onClick": editMode ? null : {
        "action": "commands/custom",
        "name": "cards/edit",
        "properties": {
          "checkItemId": checkItem.id
        }
      },
      "leftButtons": [
        {
          "styleClasses": $tpu.styles.iconButtonClasses(),
          // "styleClasses": [
          //   "icon"
          // ],
          "icon": {
            "material": {
              "name": checkIcon
            }
          },
          "type": "button",
          "onClick": {
            "action": "commands/custom",
            "name": "checkItems/update",
            "properties": {
              "checkItemId": checkItem.id,
              "cardId": card.id,
              "formData": {
                "state": ticked ? "incomplete" : "complete"
              }
            },
          }
        },
      ],
      "rightButtons": [
        {
          "styleClasses": [
            "icon", "text", "x-small", "show-on-hover"
          ],
          "icon": {
            "material": {
              "name": "more_vert"
            }
          },
          "type": "button",
          "onClick": {
            action: "popovers/open",
            placement: "right-start",
            styleClasses: ['popover-menu'],
            width: 300,
            key: "checkitem_popup",
            childViews: [
              {
                "view": "label",
                "styleClasses": ["popover-menu-item"],
                "text": "Delete",
                "onClick": {
                  "action": "commands/custom",
                  "name": "checkItems/destroy",
                  "properties": {
                    "checkItemId": checkItem.id,
                    "checklistId": checklist.id,
                    "cardId": card.id,
                  },
                  "onSuccess": {
                    action: "popovers/close",
                    key: "checkitem_popup",
                  }
                }
              }
            ]
          }
          // "childButtons": [
          //   {
          //     "text": "Delete",
          //     "onClick": {
          //       "action": "commands/custom",
          //       "name": "checkItems/destroy",
          //       "properties": {
          //         "checkItemId": checkItem.id,
          //         "checklistId": checklist.id,
          //         "cardId": card.id,
          //       },
          //     }
          //   }
          // ]
        },
      ],
      "body": editMode ? this.editPanel(checkItem, checklist, card) : {}
    }
  }

  static editPanel(checkItem, checklist, card) {
    return {
      "width": "matchParent",
      "padding": { "top": 4 },
      "childViews": [
        form.editableTitle(checkItem.name, null, {
          "action": "commands/custom",
          "name": "checkItems/update",
          "properties": {
            "checkItemId": checkItem.id,
            "checklistId": checklist.id,
            "cardId": card.id
          }
        })
      ]
    }
  }
}
